import React from "react";
import { Link } from "react-router-dom"; // Importa Link
import "./contest.css";

const YourComponent = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/background.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
        height: "100vh",
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Preloader */}
      <div id="cyb-preloader"></div>

      {/* Header */}
      <header
        id="cyb-header"
        className="cyb-main-header"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "20px",
        }}
      >
        {/* Navigation */}
        <nav
          className="cyb-main-navigation"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            marginRight: "20px",
          }}
        >
          <a className="nav-link" href="#cyb-banner" style={{ color: "white" }}>
            Inicio
          </a>
          <a className="nav-link" href="#cyb-about" style={{ color: "white" }}>
            Beneficios
          </a>
          <a className="nav-link" href="#cyb-service" style={{ color: "white" }}>
            Servicios
          </a>
          {/* Botón de Login ajustado */}
          <Link
            to="/login" // Cambia href por to y usa Link
            style={{
              color: "white",
              background: "#FF5733", // Naranja
              padding: "10px 15px",
              borderRadius: "5px",
              textDecoration: "none",
            }}
          >
            Login
          </Link>
        </nav>

        {/* Logo */}
        <div
          className="cyb-brand-logo"
          style={{
            marginLeft: "20px",
          }}
        >
          <a href="#cyb-banner">
            <img
              src={`${process.env.PUBLIC_URL}/logocli.png`}
              alt="Logo"
              style={{
                maxHeight: "300px",
                maxWidth: "300px",
                objectFit: "contain",
              }}
            />
          </a>
        </div>
      </header>

      {/* Main Banner */}
      <section
        id="cyb-banner"
        className="cyb-banner-section"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          flex: 1,
        }}
      >
        <div>
          <h2 style={{ color: "white" }}>¡Bienvenidos a nuestra página!</h2>
          <p style={{ color: "white" }}>
            Descubre todo lo que podemos ofrecerte.
          </p>
          <a
            className="btn cyb-banner-btn"
            href="#cyb-about"
            style={{
              color: "white",
              background: "#FF5733", // Naranja
              padding: "10px 15px",
              borderRadius: "5px",
              textDecoration: "none",
            }}
          >
            Saber más
          </a>
        </div>
      </section>
    </div>
  );
};

export default YourComponent;
