import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import FileProcessor from './FileProcessor';
import Contest from './Contest'; // Importación corregida

function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }, [token]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Contest />} />
        <Route path="/login" element={<Login setToken={setToken} />} />
        {token && (
          <Route
            path="/file-processor"
            element={<FileProcessor token={token} setToken={setToken} />}
          />
        )}
      </Routes>
    </Router>
  );
}

export default App;
