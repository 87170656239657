import React, { useState } from 'react';
import axios from 'axios';
import './Login.css'; // Asegúrate de importar el archivo CSS
import { useNavigate } from 'react-router-dom';




const Login = ({ setToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Define el hook de navegación

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://clikigo.com:8000/api/token/`, {
        username,
        password,
      });
      setToken(response.data.access);
      navigate('/file-processor'); // Redirige a la ruta de FileProcessor
    } catch (err) {
      setError('Credenciales inválidas');
    }
  };

  return (
    <div className="container">
      <div className="heading">Iniciar Sesión</div>
      <form onSubmit={handleSubmit} className="form">
        <input
          required
          className="input"
          type="text"
          name="username"
          id="username"
          placeholder="Usuario"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          required
          className="input"
          type="password"
          name="password"
          id="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className="forgot-password">
          <a href="#">¿Olvidaste tu contraseña?</a>
        </span>
        <input className="login-button" type="submit" value="Ingresar" />
      </form>
      <span className="agreement">
        <a href="#">Conoce el acuerdo de licencia de usuario</a>
      </span>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};


export default Login;
